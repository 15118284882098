import React from 'react';
import { createTheming } from '@callstack/react-theme-provider';
import './css/style.css';
import logo from './images/logo.png';
import logoModal from './images/logo.png';

const theme = {
  logo: { src: logo, alt: "Logo"},
  logoModal: { src: logoModal, alt: "Logo"},
  rippleColor: '#004741',
  themeColor: '#004741'
}

const { ThemeProvider, withTheme, useTheme } = createTheming(theme);

function Theme(props) {
  return (<ThemeProvider>{props.children}</ThemeProvider>)
}

export { Theme, ThemeProvider, withTheme, useTheme }
